@import './assets/css/reset.scss';

$bar-background: #fff;
$body-background: #fafafa;
$font-color: #333;
$font-color-reversed: #fafafa;
$main-color: #f3a536;
$hover-color: rgba(243, 165, 54, 0.7);

body {
  height: 100vh;
  position: relative;
  background-color: $body-background;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

::selection {
  background: $hover-color;
  color: $font-color-reversed;
}

a:hover {
  color: $hover-color;
}

.seo {
  display: none;
}

header {
  height: 60px;
  flex-shrink: 0;
  background: $bar-background;
  display: flex;
  align-items: center;
}
header .content {
  max-width: 1040px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}
header .left {
  display: flex;
  align-items: center;
  color: $font-color;
}
header .left .logo {
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  margin: 0 0.5em;
  cursor: pointer;
}
header .left .logo img {
  height: 36px;
  width: 36px;
}
header .left .link {
  margin-left: 30px;
  padding: 5px 10px;
  transition: background-color 0.3s, color 0.1s;
}
header .left .link:hover {
  background-color: $hover-color;
  color: $font-color-reversed;
}
header .right .goback {
  padding: 5px 20px;
  font-size: 17px;
  display: inline-flex;
  align-items: center;
  letter-spacing: 0.2em;
  color: $font-color;
  transition: background-color 0.3s, color 0.1s;
}
header .right .goback:hover {
  background: $hover-color;
  color: $font-color-reversed;
}
header .right .goback svg {
  width: 16px;
  height: 16px;
}
.wrapper {
  flex: 1;
  max-width: 1080px;
  margin: auto;
  color: $font-color;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.wrapper .left {
  display: flex;
  flex-direction: column;
  flex: 3;
  padding: 10vh 30px;
}

.wrapper .left h2 {
  font-size: 34px;
  margin-bottom: 30px;
}

.wrapper .left .description {
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 2;
}

.wrapper .left .download {
  display: flex;
  align-items: center;
}

.download-button-wrapper {
  position: relative;
  overflow: visible;

  .suffix {
    visibility: hidden;
    position: absolute;
    left: calc(100% + 14px);
    top: 50%;
    transform: translateY(-50%);
    padding: 10px;
    border-radius: 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background: white;
    z-index: 5;
    &::before {
      position: absolute;
      left: -6px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      z-index: 6;
      content: '';
      background: inherit;
    }
  }

  &:hover .suffix {
    visibility: visible;
  }
  p.tips {
    font-size: 12px;
    margin: 10px 0;
    color: #666;
    text-align: center;
  }
}

.download-button-wrapper + .download-button-wrapper {
  margin-left: 20px;
}

.wrapper .left .download .download-button {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 16%), 0px 2px 2px 0px rgb(0 0 0 / 12%), 0px 1px 5px 0px rgb(0 0 0 / 10%);
  border-radius: 5px;
  padding: 10px 30px;
  background-color: $main-color;
  color: white;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

  border: none;

  .row {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
  .version {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.4);
  }

  &:hover {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    transform: translateY(-2px);
  }
}

.wrapper .right {
  flex: 4;
  margin-left: 30px;
  text-align: center;
  img {
    height: 70vh;
    max-width: 100%;
  }
}

footer {
  flex-shrink: 0;
  background: $bar-background;
  color: $font-color;
  font-size: 12px;
  line-height: 1.8;
  padding: 20px 30px 16px 30px;
  text-align: center;
}

.open-in-wechat {
  position: fixed;
  width: fit-content;
  overflow: hidden;
  left: 0;
  right: 0;
  bottom: 60px;
  margin: auto;
  z-index: 20;
  padding: 10px;
}

@media (max-width: 799px) {
  body {
    height: auto;
    display: block;
    overflow: auto;
  }
  header {
    height: auto;
    position: sticky;
    top: 0;
    box-shadow: 0 1px 6px 0 rgb(32 33 36 / 15%);
  }
  .content {
    padding: 12px 12px 12px 16px;
    .logo {
      width: 28px;
      height: 28px;
    }
    .title {
      color: #000;
      font-weight: bold;
      font-size: 18px;
    }
    .goback {
      display: inline-block;
      position: relative;
      font-size: 14px;
      color: #666;
      width: 2em;
      line-height: 1.2;
      font-size: 12px;
      transform: scale(0.8);
      &::after {
        position: absolute;
        left: -4px;
        top: -4px;
        bottom: -4px;
        right: -4px;
        content: "";
      }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column-reverse;
    .right {
      margin-left: 0;
      margin-top: 60px;
      margin-bottom: 60px;
      img {
        height: 60vh;
      }
    }
    .left {
      padding: 0 16px 60px 16px;
      h2 {
        font-size: 28px;
      }
      p.description {
        font-size: 16px;
      }
      h2,
      p.description {
        text-align: center;
      }
      div.download {
        justify-content: center;
        .download-button {
          padding: 10px;
          min-width: 35vw;
        }
      }
    }

    .download-button-wrapper .suffix {
      display: none;
    }
  }

  footer {
    height: auto;
    padding-left: 20px;
    padding-right: 20px;

    .highlight {
      color: $hover-color;
    }
  }

  .toast-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 10px 20px;
    border-radius: 4px;
    margin:auto;
    width: fit-content;
    height: fit-content;
    background-color: $body-background;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
    max-width: 80vw;
    word-break: break-all;
    text-align: center;
    line-height: 1.8;
    color: #666;
    .sub {
      color: #999;
      font-size: 12px;
    }
  }
}
